<template>
  <div class="bx-content bx-vod-episodes bx-typo--vod-epg bx-typo--vod-episodes bx-search">
    <h3>Die neuesten Episoden von "{{ seriesTitle }}"</h3>
    <form class="bx-form"
          :class="{ 'bx-js-loading': isLoading }">
      <div v-if="isLoading"
           class="bx-form__loader" />
      <div class="bx-form__select-wrapper">
        <select v-model="selectedSeason"
                class="bx-form__select"
                :disabled="isLoading || numberOfSeasons === 1"
                @change="changeSeason()">
          <option v-for="i in numberOfSeasons"
                  :key="i"
                  :value="i">
            Staffel {{ i }}
            <span v-if="numberOfSeasons === 1">
              von {{ numberOfSeasons }}
            </span>
          </option>
        </select>
        <Icon v-if="numberOfSeasons > 1"
              name="arrow_toggle" />
      </div>
      <ShowMore v-if="currentSeason.episodes.length"
                :aria-controls-id="`episodes-list-${listOrderPosition}`"
                aria-content-label="Folgen"
                content-mode="list"
                :max-list-items="3"
                :show-whole-content="currentSeason.episodes.length <= 3">
        <ol>
          <li v-for="episode in currentSeason.episodes"
              :key="episode.number">
            <div>
              <figure>
                <img :src="getImageSrc(episode.stillPath)"
                     :alt="episode.title">
              </figure>
              <div>
                <div>
                  {{ episode.number }}. {{ episode.title }}
                </div>
                <div>
                  <span>Staffel {{ currentSeason.seasonNumber }} | Folge {{ episode.number }}/{{ currentSeason.episodes.length }}</span>
                  <span v-if="episode.runtime"> | {{ episode.runtime }} Minuten</span>
                </div>
              </div>
            </div>
            <div v-if="episode.description"
                 class="bx-typo--content-continuous-text">
              <p>{{ episode.description }}</p>
            </div>
          </li>
        </ol>
      </ShowMore>
      <div v-else
           class="bx-search__no-results">
        <span>
          <strong>Keine Episoden gefunden.</strong><br><br>
          Zu der ausgewählten Staffel konnten wir leider keine Episoden im System finden.
          Vielleicht ist diese Staffel noch nicht verfügbar oder wird in Kürze ergänzt.
        </span>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'pinia'

import { useConfigStore } from '../../stores/config'
import ShowMore from '../shared/ShowMore.vue'
import Icon from '../shared/Icon.vue'

export default {
  components: {
    ShowMore,
    Icon
  },
  props: {
    seriesCapiId: {
      type: String,
      required: true
    },
    seriesTitle: {
      type: String,
      required: true
    },
    seasonNumber: {
      type: Number,
      required: true
    },
    numberOfSeasons: {
      type: Number,
      required: true
    },
    listOrderPosition: {
      type: Number,
      default: null
    },
    episodes: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    // is delivered by the backend but not used in the frontend
    // eslint-disable-next-line vue/no-unused-properties
    numberOfEpisodes: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      isLoading: null,
      selectedSeason: this.seasonNumber, // season picker
      currentSeason: { // defaults on initial load
        seasonNumber: this.seasonNumber,
        episodes: this.episodes
      }
    }
  },
  computed: {
    ...mapState(useConfigStore, ['rsConfig'])
  },
  methods: {
    async changeSeason () {
      this.isLoading = true
      try {
        this.currentSeason.seasonNumber = this.selectedSeason
        const url = `${this.rsConfig.vodServiceUrl}/${this.seriesCapiId}/season/${this.selectedSeason}/with-episodes`
        const { data: { episodes } } = await axios.get(url) || {}
        this.currentSeason.episodes = episodes
      } catch (error) {
        this.currentSeason.episodes = []
        console.error('Error loading episodes:', error)
      }

      this.isLoading = null
    },
    getImageSrc (stillPath) {
      return stillPath ? `https://image.tmdb.org/t/p/w200${stillPath}` : '/images/tvmovie/fallback-episodes-teaser.webp'
    }
  }
}
</script>
